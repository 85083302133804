import style from './style.module.css'

import logo from '../../assets/bikersHouseWhite.png'

export default function Footer(){
    return (
        <footer className={style.footer}>
            <div className="container-xl">
                <div className="row p-5 align-items-center justify-content-around">
                    <div className="col-3 text-center">
                        <img className="img-fluid" src={logo} alt="BikersHouse" />
                    </div>
                    <div className="col-4 justify-content-center align-items-center">
                        <h5>Entre em contato conosco!</h5>
                        <address>
                            <p>
                                Pedidos pelo WhatsApp<br />
                                (35) 99999-9999
                            </p>

                            <p>
                                Rua: XXXXXXXXXX, Nº 99<br/>
                                Pouso Alegre - MG
                            </p>

                        </address>
                    </div>
                </div>
            </div>
            <div className={style.copy}>
                <p>&copy; Copyright 2021. Todos os diretios reservados.</p>
            </div>
        </footer>
    )
}