import banner from '../../assets/banner.jpg'
import { Title } from '../../components'

export default function HomePage(){
    return (
        <div>
            <div className="text-center">
                <img className="img-fluid" alt="BikersHouse" src={banner} />
            </div>

            <div className="container-xl">
                <Title>Sobre nós</Title>
                <Title>Onte estamos</Title>

            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3693.2569952516105!2d-45.93877458566492!3d-22.230325919669518!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cbc7b69060be31%3A0xbef0c3e55dff4f10!2sCatedral%20Metropolitana%20Bom%20Jesus!5e0!3m2!1spt-BR!2sbr!4v1611162439091!5m2!1spt-BR!2sbr" width="100%" height="350" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>

            
        </div>
    )
}