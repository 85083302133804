import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'

import {
  Footer,
  Header
} from './components'
import { HomePage } from './pages'

export default function App() {
  return (
    <div>
      <Router>
        <Header />
          <Switch>
            <Route path="/" exact component={HomePage} />
          </Switch>
        <Footer />
      </Router>
    </div>
  )
}
