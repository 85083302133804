
import {
    Navbar,
    NavbarToggler,
    Collapse,
    Nav,
    NavItem
    
} from 'reactstrap'

import {
    NavLink
} from 'react-router-dom'

import style from './style.module.css'
import bikersHouse from '../../assets/bikersHouse.png'
import { useState } from 'react'

export default function Header(){
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => setIsOpen(!isOpen)
    return (
        <header>
            <div className={style.topbar} />
            <img className={style.logo} src={bikersHouse} alt="Bikers House" />

            <Navbar className={style.backNavBar} dark expand="md">
                <NavbarToggler onClick={toggle} className={style.toggler} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav navbar className="m-auto">
                        <NavItem>
                            <NavLink className={style.navlink} exact to="/menu">HOME</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={style.navlink} exact to="/menu">SOBRE NÓS</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={style.navlink} exact to="/menu">PRODUTOS</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={style.navlink} exact to="/menu">ONDE ESTAMOS</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={style.navlink} exact to="/menu">CONTATO</NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        </header>
    )
}